<template lang="pug">
div(
    v-if="!!order"
)
    //- name bar
    TopBar
        OrderName(
            :order="order"
        )

    //- jobs
    OrderLines(
        :order="order"
    )

    //- buttons
    div(class="buttons-height-1 mt-4")
        //- div(class="page-buttons-wrapper")
        //-     Container
        //-         IonRow(class="receding")
        //-             IonCol(
        //-                 size="12"
        //-             )
        //-                 IonButton(
        //-                     color="secondary"
        //-                     size="large"
        //-                     expand="block"
        //-                     @click="$router.push({ name: 'order-5', params: { id: order.id } })"
        //-                 )
        //-                     IonText(
        //-                         color="primary"
        //-                     )
        //-                         h3(class="text-center") Auftrag starten
</template>

<script>
import TopBar from "@/components/sections/TopBar"
import OrderName from "@/components/molecules/OrderName"
import CheckableButton from "@/components/molecules/CheckableButton"
import CustomRow from "@/components/molecules/CustomRow"
import Box from "@/components/molecules/Box"
import OrderLines from "@/components/molecules/OrderLines"
export default {
    components: {
        TopBar,
        OrderName,
        CheckableButton,
        Box,
        CustomRow,
        OrderLines
    },
    props: {
        order: {
            required: true
        }
    }
}
</script>